<template>
  <div>
    <!-- @remind="onremind"
      :reminders="[5, 10, 20, 60]" -->
    <v-idle
      @idle="onidle"
      :loop="true"
      :events="['mousemove']"
      :duration="300"
    />
    <v-data-table
      v-if="!progress"
      :headers="headers"
      :items="desserts"
      sort-by="calories"
      class="elevation-1 myClass"
      multiSort
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title v-if="windowSize > 767" style="color: red;"
            >Draw Uploads</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="black" dark class="mb-2" outlined @click="sendEmail">
            Email
            <v-icon right dark>
              mdi-cloud-upload
            </v-icon>
          </v-btn>
          <!-- <v-btn color="black" dark class="mb-2" outlined @click="signDocument">
            sign Doc
            <v-icon right dark>
              mdi-account-edit
            </v-icon>
          </v-btn> -->
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="black"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                v-if="userName !== 'Michael van Rooyen'"
              >
                Uploads
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="formTitle === 'New Item'"
                    >
                      <span style="color: red;"
                        ><strong
                          >Please ensure the documents are uploaded where
                          stated</strong
                        ></span
                      >
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="formTitle === 'New Item'"
                    >
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp, image/jpg, application/pdf"
                        label="Momentum & Draws"
                        v-model="uploadUnsignedDoc1"
                        clearable
                        @change="showFile"
                      ></v-file-input>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="formTitle === 'New Item'"
                    >
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp, image/jpg, application/pdf"
                        label="Allocations"
                        v-model="uploadUnsignedDoc2"
                        clearable
                        @change="showFile"
                      ></v-file-input>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="formTitle === 'New Item'"
                    >
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp, image/jpg, application/pdf"
                        label="Previous Draws"
                        v-model="uploadUnsignedDoc3"
                        clearable
                        @change="showFile"
                      ></v-file-input>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="formTitle === 'New Item'"
                    >
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp, image/jpg, application/pdf"
                        label="Invoice/s"
                        v-model="uploadUnsignedDoc4"
                        clearable
                        @change="showFile"
                      ></v-file-input>
                    </v-col>
                    <!-- <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="formTitle === 'New Item'"
                    >
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp, image/jpg, application/pdf"
                        label="Recovery Statement"
                        v-model="uploadUnsignedDoc5"
                        clearable
                        @change="showFile"
                      ></v-file-input>
                    </v-col> -->
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="formTitle !== 'New Item'"
                    >
                      <v-file-input
                        accept="image/png"
                        label="Upload Signature"
                        v-model="uploadSignature"
                        clearable
                        @change="showFile"
                      ></v-file-input>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="
                        formTitle !== 'New Item' && userName !== 'Nick Morgan'
                      "
                    >
                      <v-file-input
                        accept="image/png"
                        label="Upload Initial"
                        v-model="uploadInitial"
                        clearable
                        @change="showFile"
                      ></v-file-input>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="formTitle !== 'New Item'"
                    >
                      <span style="color: red;"
                        ><strong
                          >Your signature is never saved by the system and
                          deleted immedietly.</strong
                        ></span
                      ><br />
                      <span
                        >I {{ userName }} agree that I have read the
                        documentation that I am about to sign and acknowledge it
                        as true and correct</span
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="formTitle !== 'New Item'"
                    >
                      <v-checkbox
                        v-model="ex4"
                        label="Agreed"
                        color="success"
                        value="success"
                        hide-details
                      ></v-checkbox>
                    </v-col>

                    <!-- <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="formTitle !== 'New Item'"
                    >
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp, image/jpg, application/pdf"
                        label="Doc2"
                        v-model="uploadSignedDoc2"
                        clearable
                        @change="showFile"
                      ></v-file-input>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="formTitle !== 'New Item'"
                    >
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp, image/jpg, application/pdf"
                        label="Doc3"
                        v-model="uploadSignedDoc3"
                        clearable
                        @change="showFile"
                      ></v-file-input>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="formTitle !== 'New Item'"
                    >
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp, image/jpg, application/pdf"
                        label="Doc4"
                        v-model="uploadSignedDoc4"
                        clearable
                        @change="showFile"
                      ></v-file-input>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      v-if="formTitle !== 'New Item'"
                    >
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp, image/jpg, application/pdf"
                        label="Doc5"
                        v-model="uploadSignedDoc5"
                        clearable
                        @change="showFile"
                      ></v-file-input>
                    </v-col> -->
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer v-if="formTitle === 'New Item'"></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  v-if="formTitle === 'New Item'"
                >
                  Save
                </v-btn>
                <v-spacer v-if="formTitle !== 'New Item'"></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  v-if="
                    formTitle !== 'New Item' && ex4 && uploadSignature !== null
                  "
                >
                  Sign
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          class="mr-2"
          color="green"
          id="Nick Morgan"
          @click="editItem(item, $event)"
          v-if="
            (userName === 'Nick Morgan' || userName === 'Wayne Bruton') &&
              item.signedBy1 === null
          "
        >
          mdi-feather
        </v-icon>
        <v-icon
          class="mr-2"
          color="blue"
          id="Wynand Haywood"
          @click="editItem(item, $event)"
          v-if="
            (userName === 'Wynand Haywood' || userName === 'Wayne Bruton') &&
              item.signedBy2 === null
          "
        >
          mdi-feather
        </v-icon>
        <v-icon
          class="mr-2"
          color="black"
          id="Deon van Rooyen"
          @click="editItem(item, $event)"
          v-if="
            (userName === 'Deon van Rooyen' || userName === 'Wayne Bruton') &&
              item.signedBy3 === null
          "
        >
          mdi-feather
        </v-icon>
        <v-icon
          color="red"
          @click="deleteItem(item)"
          v-if="
            userName !== 'Deon van Rooyen' &&
              userName !== 'Wynand Haywood' &&
              userName !== 'Nick Morgan'
          "
        >
          mdi-delete
        </v-icon>
      </template>
      <template #item.uploadUnsignedDoc1="{ item }">
        <a target="_blank" :href="item.uploadUnsignedDoc1" style="color: red;">
          <v-icon>
            mdi-file-pdf
          </v-icon>
          {{ item.unsigned1 }}
        </a>
      </template>
      <template #item.uploadUnsignedDoc2="{ item }">
        <a target="_blank" :href="item.uploadUnsignedDoc2" style="color: red;">
          {{ item.unsigned2 }}
        </a>
      </template>
      <template #item.uploadUnsignedDoc3="{ item }">
        <a target="_blank" :href="item.uploadUnsignedDoc3" style="color: red;">
          {{ item.unsigned3 }}
        </a>
      </template>
      <template #item.uploadUnsignedDoc4="{ item }">
        <a target="_blank" :href="item.uploadUnsignedDoc4" style="color: red;">
          {{ item.unsigned4 }}
        </a>
      </template>
      <!-- <template #item.uploadUnsignedDoc5="{ item }">
        <a target="_blank" :href="item.uploadUnsignedDoc5" style="color: red;">
          {{ item.unsigned5 }}
        </a>
      </template> -->
      <template #item.uploadSignedDoc1="{ item }">
        <a target="_blank" :href="item.uploadSignedDoc1" style="color: green;">
          {{ item.Signed1 }}
        </a>
      </template>
      <!-- <template #item.uploadSignedDoc2="{ item }">
        <a target="_blank" :href="item.uploadSignedDoc2" style="color: green;">
          {{ item.Signed2 }}
        </a>
      </template>
      <template #item.uploadSignedDoc3="{ item }">
        <a target="_blank" :href="item.uploadSignedDoc3" style="color: green;">
          {{ item.Signed3 }}
        </a>
      </template>
      <template #item.uploadSignedDoc4="{ item }">
        <a target="_blank" :href="item.uploadSignedDoc4" style="color: green;">
          {{ item.Signed4 }}
        </a>
      </template> -->
      <!-- <template #item.uploadSignedDoc5="{ item }">
        <a target="_blank" :href="item.uploadSignedDoc5" style="color: green;">
          {{ item.Signed5 }}
        </a>
      </template> -->
      <!-- <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template> -->
    </v-data-table>
    <v-dialog
      v-model="dialogAuth"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="black">
          <v-toolbar-title>No Authorization</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list three-line subheader>
          <!-- <v-subheader>Redirecting</v-subheader> -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><h1>
                  Redirecting in {{ timeleft }} seconds
                </h1></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-row justify="center" v-if="progress">
      <v-progress-circular
        :size="180"
        :width="15"
        color="black"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogEmail" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Choose Recipients</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="8" sm="8">
                  <v-autocomplete
                    :items="users"
                    v-model="emailAddresses"
                    item-text="userName"
                    label="Recipients"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogEmail = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="sendFinalEmail">
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogRedirect" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >This screen is being used by {{ lockedBy }}.</span
            >
            <span class="text-h5"
              >Please wait for {{ lockedBy }} to exit the screen.</span
            >
            <span class="text-h5">You will shortly be redirected</span>
          </v-card-title>
          <v-card-text>
            <v-container> </v-container>
          </v-card-text>
          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogEmail = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="sendFinalEmail">
              Send
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="snackbar" top>
      {{ snackBarMessage }}
      <v-btn color="pink" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <!-- <Vidle /> -->
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import axios from "axios";
// import Vidle from 'v-idle'
// import IdleVue from 'idle-vue'
// import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
let url = process.env.VUE_APP_BASEURL;
export default {
  name: "DrawUploads",
  components: {
    // Vidle
  },
  data: () => ({
    windowSize: 0,
    dialogRedirect: false,
    progress: false,
    ex4: false,
    snackbar: false,
    snackBarMessage: "",
    emailAddresses: null,
    dialogEmail: false,
    dialogAuth: false,
    userName: "",
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Actions", value: "actions", sortable: false, width: 180 },
      {
        text: "Doc1",
        align: "center",
        sortable: true,
        value: "uploadUnsignedDoc1",
        width: 180
      },
      {
        text: "Doc2",
        align: "center",
        sortable: true,
        value: "uploadUnsignedDoc2",
        width: 180
      },
      {
        text: "Doc3",
        align: "center",
        sortable: true,
        value: "uploadUnsignedDoc3",
        width: 180
      },
      {
        text: "Doc4",
        align: "center",
        sortable: true,
        value: "uploadUnsignedDoc4",
        width: 180
      },
      // {
      //   text: "Unsigned5",
      //   align: "center",
      //   sortable: true,
      //   value: "uploadUnsignedDoc5",
      //   width: 100
      // },
      {
        text: "User",
        align: "start",
        sortable: true,
        value: "uploadUnsignedDocUser",
        width: 100
      },
      {
        text: "Submission Date",
        align: "start",
        sortable: true,
        value: "uploadUnsignedDocDate",
        width: 150
      },
      // {
      //   text: "signed1",
      //   align: "center",
      //   sortable: true,
      //   value: "uploadSignedDoc1",
      //   width: 200
      // },
      // {
      //   text: "signed2",
      //   align: "center",
      //   sortable: true,
      //   value: "uploadSignedDoc2",
      //   width: 100
      // },
      // {
      //   text: "signed3",
      //   align: "center",
      //   sortable: true,
      //   value: "uploadSignedDoc3",
      //   width: 100
      // },
      // {
      //   text: "signed4",
      //   align: "center",
      //   sortable: true,
      //   value: "uploadSignedDoc4",
      //   width: 100
      // },
      {
        text: "signed Doc",
        align: "center",
        sortable: true,
        value: "uploadSignedDoc1",
        width: 180
      },
      {
        text: "SignedBy",
        align: "start",
        sortable: true,
        value: "signedBy1",
        width: 100
      },
      {
        text: "date",
        align: "start",
        sortable: true,
        value: "uploadSignedDocDate1",
        width: 150
      },
      {
        text: "SignedBy",
        align: "start",
        sortable: true,
        value: "signedBy2",
        width: 100
      },
      {
        text: "date",
        align: "start",
        sortable: true,
        value: "uploadSignedDocDate2",
        width: 150
      },
      {
        text: "SignedBy",
        align: "start",
        sortable: true,
        value: "signedBy3",
        width: 100
      },
      {
        text: "date",
        align: "start",
        sortable: true,
        value: "uploadSignedDocDate3",
        width: 150
      }
      // {
      //   text: "submission",
      //   align: "center",
      //   sortable: true,
      //   value: "submissionNumber",
      //   width: 100,
      // },

      // { text: "Actions", value: "actions", sortable: false, width: 180 },
    ],
    desserts: [],
    editedIndex: -1,
    uploadUnsignedDoc1: null,
    uploadUnsignedDoc2: null,
    uploadUnsignedDoc3: null,
    uploadUnsignedDoc4: null,
    uploadUnsignedDoc5: null,
    uploadUnsignedDocUser: "",
    uploadUnsignedDocDate: "",
    // uploadSignedDoc1: null,
    // uploadSignedDoc2: null,
    // uploadSignedDoc3: null,
    // uploadSignedDoc4: null,
    // uploadSignedDoc5: null,
    uploadSignedDocUser: "",
    uploadSignedDocDate: "",
    uploadSignature: null,
    uploadInitial: null,
    idToEdit: 0,
    fileNameToSign: null,
    documentsToSign: [],
    signedDocumentCount: 0,
    // uploadSignedDate: null,

    editedItem: {
      id: 0,
      uploadUnsignedDoc1: null,
      uploadUnsignedDoc2: null,
      uploadUnsignedDoc3: null,
      uploadUnsignedDoc4: null,
      uploadUnsignedDoc5: null,
      uploadUnsignedDocUser: "",
      uploadUnsignedDocDate: "",
      // uploadSignedDoc1: null,
      // uploadSignedDoc2: null,
      // uploadSignedDoc3: null,
      // uploadSignedDoc4: null,
      // uploadSignedDoc5: null,
      uploadSignedDocUser: "",
      uploadSignedDocDate: "",
      submissionNumber: null
    },
    defaultItem: {
      id: 0,
      uploadUnsignedDoc1: null,
      uploadUnsignedDoc2: null,
      uploadUnsignedDoc3: null,
      uploadUnsignedDoc4: null,
      uploadUnsignedDoc5: null,
      uploadUnsignedDocUser: "",
      uploadUnsignedDocDate: "",
      // uploadSignedDoc1: null,
      // uploadSignedDoc2: null,
      // uploadSignedDoc3: null,
      // uploadSignedDoc4: null,
      // uploadSignedDoc5: null,
      uploadSignedDocUser: "",
      uploadSignedDocDate: "",
      submissionNumber: null
    },
    timeleft: 0,
    submissionNumber: 0,
    users: [],
    signedByNick: false,
    signedByWynand: false,
    signedByDeon: false,
    signatory: "",
    RUNumber: "",
    lockedBy: ""
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    }

    // isIdle() {
    // 	return this.$store.state.idleVue.isIdle;
    // }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  beforeCreate() {},

  async beforeDestroy() {
    this.updateHandler();
    // this.unlock();
    // if (this.lockedBy === "" || this.lockedBy === this.$store.state.userName) {
    //   console.log("Before Destroy");
    //   await axios({
    //     method: "post",
    //     url: `${url}/clearLockedWB`
    //   })
    //     .then(
    //       () => {},
    //       error => {
    //         console.log(error);
    //       }
    //     )
    //     .catch(e => {
    //       console.log(e);
    //     });
    // }
  },
  destroyed() {
    // window.removeEventListener("beforeunload", this.updateHandler);
  },
  created() {
    // window.addEventListener('beforeunload', this.removeUser)
    // window.addEventListener("beforeunload", this.updateHandler);
    // window.addEventListener('beforeunload', function (e) {
    //         e.preventDefault();
    //         e.returnValue = alert("Testing");
    //     });

    //         alert("Testing")

    // window.addEventListener('beforeunload', () => {
    //         //I CAN ACCESS TO this VARIABLE
    //         alert("Testing")
    //         console.log("Testing");
    //     }, false)
    // window.addEventListener("beforeunload", this.unlock());
    this.windowSize = window.innerWidth;
    console.log("AWESOME!!!!");
    // this.checkLocked();
    this.userName = this.$store.state.userName;
    // if (
    //   this.$store.state.userName !== "Wayne Bruton" &&
    //   this.$store.state.userName !== "Herbert du Plessis" &&
    //   this.$store.state.userName !== "Dirk Coetzee" &&
    //   this.$store.state.userName !== "Wynand Haywood" &&
    //   this.$store.state.userName !== "Michael van Rooyen"
    // ) {
    //   this.dialogAuth = true;
    //   this.timeleft = 3;
    //   let downloadTimer = setInterval(() => {
    //     if (this.timeleft <= 1) {
    //       clearInterval(downloadTimer);
    //       this.$router.push("/");
    //     }
    //     this.timeleft -= 1;
    //   }, 1000);
    // } else {
    this.getInitialData();
    // }
  },

  methods: {
    // removeUser() {
    //   if (window.performance) {
    //     console.log("window.performance works fine on this browser");
    //   }
    //   if (window.performance.navigation.type != 1) {
    //     alert("There is an issue")
    //   }
    // },

    // updateHandler(e) {
    //   alert("Testing This Out", e);
    // },
    onidle() {
      // alert("You have been logged out");
      //  this.$router.push({ name: "Home" });
      //  this.snackBarMessage = "Data refreshed."
      //  this.snackbar = true
      //  setTimeout(() => {
      this.getInitialData();
      //  })
    },
    // onremind(time) {
    //   // alert seconds remaining to 00:00
    //   alert(time);
    // },
    // async unlock() {
    //   if (
    //     this.lockedBy === "" ||
    //     this.lockedBy === this.$store.state.userName
    //   ) {
    //     console.log("Before Destroy");
    //     await axios({
    //       method: "post",
    //       url: `${url}/clearLockedWB`,
    //     })
    //       .then(
    //         () => {},
    //         (error) => {
    //           console.log(error);
    //         }
    //       )
    //       .catch((e) => {
    //         console.log(e);
    //       });
    //   }
    // },
    // async postLockedBy() {
    //   let data = {
    //     user: this.$store.state.userName,
    //   };
    //   await axios({
    //     method: "post",
    //     url: `${url}/lockDrawWB`,
    //     data: data,
    //   })
    //     .then(
    //       (response) => {
    //         console.log(response.data);
    //         this.getInitialData();
    //       },
    //       (error) => {
    //         console.log(error);
    //       }
    //     )
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    // async checkLocked() {
    //   await axios({
    //     method: "post",
    //     url: `${url}/checkDrawLockedWB`,
    //   })
    //     .then(
    //       (response) => {
    //         console.log("The Response", response.data);
    //         if (response.data.available) {
    //           console.log("Do Something");
    //           this.postLockedBy();
    //         } else {
    //           this.lockedBy = response.data[0].user;
    //           if (this.lockedBy !== this.$store.state.userName) {
    //             console.log("lockedBy", this.lockedBy);
    //             this.dialogRedirect = true;
    //             setTimeout(() => {
    //               this.$router.push({ name: "Home" });
    //             }, 3000);
    //           } else {
    //             this.getInitialData();
    //           }
    //           // console.log("Redirect")
    //         }
    //       },
    //       (error) => {
    //         console.log(error);
    //       }
    //     )
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    async signDocumentNick() {
      let updateFiles = [];
      updateFiles.push(this.uploadSignature);
      updateFiles.push(this.uploadInitial);
      console.log(this.uploadSignature);
      console.log(this.uploadInitial);
      console.log("updateFiles", updateFiles);

      let formData = new FormData();
      formData.append("files", this.uploadSignature);
      formData.append("files", this.uploadInitial);
      formData.append("user", this.uploadSignedDocUser);
      formData.append("devId", this.$store.state.development.id);
      formData.append("signDate", this.uploadSignedDocDate);
      formData.append("idToEdit", this.idToEdit);
      formData.append("filesToSign", this.documentsToSign);
      formData.append("signedCount", this.signedDocumentCount);
      formData.append("submissionNumber", this.submissionNumber);
      formData.append(
        "development",
        this.$store.state.development.developmentName.replace(/\s/g, "")
      );
      formData.append("RUNumber", this.RUNumber);

      await axios({
        method: "post",
        url: `${url}/SignDrawPDFNickWB`,
        data: formData
      })
        .then(
          response => {
            console.log("The Response", response.data);
            if (response.data.length > 1) {
              if (response.data[0].affectedRows === 1) {
                this.uploadSignature = null;
                this.uploadSignedDocUser = "";
                this.uploadSignedDocDate = "";
                this.idToEdit = 0;
                this.fileNameToSign = "";
                this.snackBarMessage = "Document successfully signed";
                this.snackbar = true;
                this.ex4 = false;
                this.close();
                // window.location.reload();
                this.desserts = [];
                this.progress = false;
                // window.history.forward(1)
                this.getInitialData();
              } else {
                this.snackBarMessage = "There was an issue, please try later";
                this.uploadSignature = null;
                this.uploadSignedDocUser = "";
                this.uploadSignedDocDate = "";
                this.idToEdit = 0;
                this.fileNameToSign = "";
                this.ex4 = false;
                this.snackbar = true;
              }
            } else {
              if (response.data.affectedRows === 1) {
                this.uploadSignature = null;
                this.uploadSignedDocUser = "";
                this.uploadSignedDocDate = "";
                this.idToEdit = 0;
                this.fileNameToSign = "";
                this.snackBarMessage = "Document successfully signed";
                this.snackbar = true;
                this.ex4 = false;
                this.close();
                // window.location.reload();
                this.desserts = [];
                this.progress = false;
                // window.history.forward(1)
                this.getInitialData();

                // this.getInitialData();
              } else {
                this.snackBarMessage = "There was an issue, please try later";
                this.uploadSignature = null;
                this.uploadSignedDocUser = "";
                this.uploadSignedDocDate = "";
                this.idToEdit = 0;
                this.fileNameToSign = "";
                this.ex4 = false;
                this.snackbar = true;
              }
            }
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    },
    async signDocumentDeon() {
      let updateFiles = [];
      updateFiles.push(this.uploadSignature);
      updateFiles.push(this.uploadInitial);
      console.log(this.uploadSignature);
      console.log(this.uploadInitial);
      console.log("updateFiles", updateFiles);

      let formData = new FormData();
      formData.append("files", this.uploadSignature);
      formData.append("files", this.uploadInitial);
      formData.append("user", this.uploadSignedDocUser);
      formData.append("devId", this.$store.state.development.id);
      formData.append("signDate", this.uploadSignedDocDate);
      formData.append("idToEdit", this.idToEdit);
      formData.append("filesToSign", this.documentsToSign);
      formData.append("signedCount", this.signedDocumentCount);
      formData.append("submissionNumber", this.submissionNumber);
      formData.append(
        "development",
        this.$store.state.development.developmentName.replace(/\s/g, "")
      );
      formData.append("RUNumber", this.RUNumber);

      await axios({
        method: "post",
        url: `${url}/SignDrawPDFDeonWB`,
        data: formData
      })
        .then(
          response => {
            console.log("The Response", response.data);
            if (response.data.length > 1) {
              if (response.data[0].affectedRows === 1) {
                this.uploadSignature = null;
                this.uploadSignedDocUser = "";
                this.uploadSignedDocDate = "";
                this.idToEdit = 0;
                this.fileNameToSign = "";
                this.snackBarMessage = "Document successfully signed";
                this.snackbar = true;
                this.ex4 = false;
                this.close();
                // window.location.reload();
                this.desserts = [];
                this.progress = false;
                // window.history.forward(1)
                this.getInitialData();
                // this.getInitialData();
              } else {
                this.snackBarMessage = "There was an issue, please try later";
                this.uploadSignature = null;
                this.uploadSignedDocUser = "";
                this.uploadSignedDocDate = "";
                this.idToEdit = 0;
                this.fileNameToSign = "";
                this.ex4 = false;
                this.snackbar = true;
              }
            } else {
              if (response.data.affectedRows === 1) {
                this.uploadSignature = null;
                this.uploadSignedDocUser = "";
                this.uploadSignedDocDate = "";
                this.idToEdit = 0;
                this.fileNameToSign = "";
                this.snackBarMessage = "Document successfully signed";
                this.snackbar = true;
                this.ex4 = false;
                this.close();
                // window.location.reload();
                this.desserts = [];
                this.progress = false;
                // window.history.forward(1)
                this.getInitialData();

                // this.getInitialData();
              } else {
                this.snackBarMessage = "There was an issue, please try later";
                this.uploadSignature = null;
                this.uploadSignedDocUser = "";
                this.uploadSignedDocDate = "";
                this.idToEdit = 0;
                this.fileNameToSign = "";
                this.ex4 = false;
                this.snackbar = true;
              }
            }
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    },
    async signDocumentWynand() {
      let updateFiles = [];
      updateFiles.push(this.uploadSignature);
      updateFiles.push(this.uploadInitial);
      console.log(this.uploadSignature);
      console.log(this.uploadInitial);
      console.log("updateFiles", updateFiles);

      let formData = new FormData();
      formData.append("files", this.uploadSignature);
      formData.append("files", this.uploadInitial);
      formData.append("user", this.uploadSignedDocUser);
      formData.append("devId", this.$store.state.development.id);
      formData.append("signDate", this.uploadSignedDocDate);
      formData.append("idToEdit", this.idToEdit);
      formData.append("filesToSign", this.documentsToSign);
      formData.append("signedCount", this.signedDocumentCount);
      formData.append("submissionNumber", this.submissionNumber);
      formData.append(
        "development",
        this.$store.state.development.developmentName.replace(/\s/g, "")
      );
      formData.append("RUNumber", this.RUNumber);
      await axios({
        method: "post",
        url: `${url}/SignDrawPDFWynandWB`,
        data: formData
      })
        .then(
          response => {
            console.log("The Response", response.data);
            if (response.data.length > 1) {
              if (response.data[0].affectedRows === 1) {
                this.uploadSignature = null;
                this.uploadSignedDocUser = "";
                this.uploadSignedDocDate = "";
                this.idToEdit = 0;
                this.fileNameToSign = "";
                this.snackBarMessage = "Document successfully signed";
                this.snackbar = true;
                this.ex4 = false;
                this.close();
                // window.location.reload();
                this.desserts = [];
                this.progress = false;
                // window.history.forward(1)
                this.getInitialData();
                // this.getInitialData();
              } else {
                this.snackBarMessage = "There was an issue, please try later";
                this.uploadSignature = null;
                this.uploadSignedDocUser = "";
                this.uploadSignedDocDate = "";
                this.idToEdit = 0;
                this.fileNameToSign = "";
                this.ex4 = false;
                this.snackbar = true;
              }
            } else {
              if (response.data.affectedRows === 1) {
                this.uploadSignature = null;
                this.uploadSignedDocUser = "";
                this.uploadSignedDocDate = "";
                this.idToEdit = 0;
                this.fileNameToSign = "";
                this.snackBarMessage = "Document successfully signed";
                this.snackbar = true;
                this.ex4 = false;
                this.close();
                // window.location.reload();

                this.desserts = [];
                this.progress = false;
                // window.history.forward(1)
                this.getInitialData();

                // this.getInitialData();
              } else {
                this.snackBarMessage = "There was an issue, please try later";
                this.uploadSignature = null;
                this.uploadSignedDocUser = "";
                this.uploadSignedDocDate = "";
                this.idToEdit = 0;
                this.fileNameToSign = "";
                this.ex4 = false;
                this.snackbar = true;
              }
            }
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    },
    async sendFinalEmail() {
      console.log("emailAddresses", this.emailAddresses);
      let addresses = [];
      this.emailAddresses.forEach(el => {
        let filtered = this.users.filter(el2 => {
          return el === el2.userName;
        })[0].userEmail;
        addresses.push(filtered);
      });

      console.log("XXAWESOME:", addresses);
      let data = {
        id: this.$store.state.development.id,
        name: this.$store.state.development.developmentName,
        user: this.$store.state.userName,
        // path: `http://localhost:8080${this.$route.fullPath}`,
        path: `https://www.cape-projects.co.za${this.$route.fullPath}`,
        addresses: addresses
      };
      console.log(data);
      await axios({
        method: "post",
        url: `${url}/postSmartUpladEmailsWB`,
        data: data
      })
        .then(
          response => {
            console.log("The Response", response.data);
            this.dialogEmail = false;
            this.emailAddresses = [];
            if (response.data.success) {
              this.snackBarMessage = "Email sent";
              this.snackbar = true;
              this.getInitialData();
            }
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    },
    async sendEmail() {
      console.log(`${url}${this.$route.fullPath}`);
      let data = {
        currentUser: this.$store.state.userName
      };
      await axios({
        method: "post",
        url: `${url}/getUsersForEmailWB`,
        data: data
      })
        .then(
          response => {
            console.log("The Response", response.data);
            this.users = response.data;

            this.dialogEmail = true;
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    },
    showFile() {
      console.log("uploadSignature", this.uploadSignature);
    },
    async getInitialData() {
      this.signedDocumentCount = 0;
      let data = {
        id: this.$store.state.development.id
      };
      await axios({
        method: "post",
        url: `${url}/getdrawUploadDataWB`,
        data: data
      })
        .then(
          response => {
            console.log("The Response", response.data);
            let test = this.$store.state.development.developmentName.replace(
              /\s/g,
              ""
            );
            console.log("test", test);
            this.desserts = response.data;
            if (!this.desserts.length) {
              if (this.$store.state.development.id === 1) {
                this.submissionNumber = 13;
              } else if (this.$store.state.development.id === 2) {
                this.submissionNumber = 6;
              }
            } else {
              this.submissionNumber = this.desserts[0].submissionNumber + 1;
            }
            console.log(this.submissionNumber);
            this.defaultItem.submissionNumber = this.submissionNumber;
            console.log(this.submissionNumber);
            this.desserts.forEach((el, index) => {
              if (
                el.uploadUnsignedDoc1 !== "" &&
                el.uploadUnsignedDoc1 !== null
              ) {
                // el.unsigned1 = `doc1`;
                el.doc1 = el.uploadUnsignedDoc1;
                console.log("uploadUnsignedDoc1", el.uploadUnsignedDoc1);

                el.unsigned1 = `${el.uploadUnsignedDoc1.substring(0, 20)}...`;
                el.uploadUnsignedDoc1 = `${url}/${el.uploadUnsignedDoc1}`;
                // el.uploadUnsignedDoc1 = `${url}/${el.uploadUnsignedDoc1}`;
              }
              if (
                el.uploadUnsignedDoc2 !== "" &&
                el.uploadUnsignedDoc2 !== null
              ) {
                // el.unsigned2 = `doc2`;
                el.doc2 = el.uploadUnsignedDoc2;
                el.unsigned2 = `${el.uploadUnsignedDoc2.substring(0, 20)}...`;
                // el.unsigned2 = `${el.uploadUnsignedDoc2.substring(0, 10)}...`;
                el.uploadUnsignedDoc2 = `${url}/${el.uploadUnsignedDoc2}`;
              }
              if (
                el.uploadUnsignedDoc3 !== "" &&
                el.uploadUnsignedDoc3 !== null
              ) {
                // el.unsigned3 = `doc3`;
                el.doc3 = el.uploadUnsignedDoc3;
                el.unsigned3 = `${el.uploadUnsignedDoc3.substring(0, 20)}...`;
                // el.unsigned3 = `${el.uploadUnsignedDoc3.substring(0, 10)}...`;
                el.uploadUnsignedDoc3 = `${url}/${el.uploadUnsignedDoc3}`;
              }
              if (
                el.uploadUnsignedDoc4 !== "" &&
                el.uploadUnsignedDoc4 !== null
              ) {
                // el.unsigned4 = `doc4`;
                el.doc4 = el.uploadUnsignedDoc4;
                el.unsigned4 = `${el.uploadUnsignedDoc4.substring(0, 20)}...`;
                // el.unsigned4 = `${el.uploadUnsignedDoc4.substring(0, 10)}...`;
                el.uploadUnsignedDoc4 = `${url}/${el.uploadUnsignedDoc4}`;
              }
              if (el.uploadUnsignedDocDate !== null) {
                el.uploadUnsignedDocDate = dayjs(
                  el.uploadUnsignedDocDate
                ).format("YYYY-MM-DD HH:mm");
              }
              if (el.uploadSignedDocDate1 !== null) {
                el.uploadSignedDocDate1 = dayjs(el.uploadSignedDocDate1).format(
                  "YYYY-MM-DD HH:mm"
                );
              }
              if (el.uploadSignedDocDate2 !== null) {
                el.uploadSignedDocDate2 = dayjs(el.uploadSignedDocDate2).format(
                  "YYYY-MM-DD HH:mm"
                );
              }
              if (el.uploadSignedDocDate3 !== null) {
                el.uploadSignedDocDate3 = dayjs(el.uploadSignedDocDate3).format(
                  "YYYY-MM-DD HH:mm"
                );
              }
              // if (
              //   el.uploadUnsignedDoc5 !== "" &&
              //   el.uploadUnsignedDoc5 !== null
              // ) {
              //   el.unsigned5 = `${el.uploadUnsignedDoc5.substring(0, 10)}...`;
              //   el.uploadUnsignedDoc5 = `${url}/${el.uploadUnsignedDoc5}`;
              // }
              if (el.uploadSignedDoc1 !== "" && el.uploadSignedDoc1 !== null) {
                el.Signed1 = `${el.uploadSignedDoc1}`;
                // el.Signed1 = `${el.uploadSignedDoc1.substring(0, 10)}...`;
                el.uploadSignedDoc1 = `${url}/${el.uploadSignedDoc1}`;
                console.log(el.uploadSignedDoc1);
              }
              if (el.uploadSignedDoc2 !== "" && el.uploadSignedDoc2 !== null) {
                el.Signed2 = `${el.uploadSignedDoc2.substring(0, 10)}...`;
                el.uploadSignedDoc2 = `${url}/${el.uploadSignedDoc2}`;
              }
              if (el.uploadSignedDoc3 !== "" && el.uploadSignedDoc3 !== null) {
                el.Signed3 = `${el.uploadSignedDoc3.substring(0, 10)}...`;
                el.uploadSignedDoc3 = `${url}/${el.uploadSignedDoc3}`;
                console.log(el.uploadSignedDoc3);
              }
              if (el.uploadSignedDoc4 !== "" && el.uploadSignedDoc4 !== null) {
                el.Signed4 = `${el.uploadSignedDoc4.substring(0, 10)}...`;
                el.uploadSignedDoc4 = `${url}/${el.uploadSignedDoc4}`;
              }
              if (index === 0) {
                if (el.signedBy1 !== null) {
                  this.signedByNick = true;
                } else {
                  this.signedByNick = false;
                }
                if (el.signedBy2 !== null) {
                  this.signedByWynand = true;
                } else {
                  this.signedByWynand = false;
                }
                if (el.signedBy3 !== null) {
                  this.signedByDeon = true;
                } else {
                  this.signedByDeon = false;
                }
              }
              // if (el.uploadSignedDoc5 !== "" && el.uploadSignedDoc5 !== null) {
              //   el.Signed5 = `${el.uploadSignedDoc5.substring(0, 10)}...`;
              //   el.uploadSignedDoc5 = `${url}/${el.uploadSignedDoc5}`;
              // }
            });
            if (this.desserts.length) {
              if (this.desserts[0].signedBy1 !== null) {
                this.signedDocumentCount++;
              }
              if (this.desserts[0].signedBy2 !== null) {
                this.signedDocumentCount++;
              }
              if (this.desserts[0].signedBy3 !== null) {
                this.signedDocumentCount++;
              }
              console.log("signedDocumentCount", this.signedDocumentCount);
              if (this.$store.state.development.id === 1) {
                this.RUNumber = "RU502229883";
              } else if (this.$store.state.development.id === 2) {
                this.RUNumber = "RU502229930";
              }
            }
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    },

    async getSignedDocs() {
      console.log("Check", this.desserts[0]);
      let data = {
        id: this.$store.state.development.id,
        uploadSignedDoc1: this.desserts[0].doc1,
        uploadSignedDoc2: this.desserts[0].doc2,
        uploadSignedDoc3: this.desserts[0].doc3,
        uploadSignedDoc4: this.desserts[0].doc4
      };
      await axios({
        method: "post",
        url: `${url}/getdrawUploadDataLatestWB`,
        data: data
      })
        .then(
          response => {
            console.log("The ResponseXXXX", response.data);
            if (response.data.length === 0) {
              this.getInitialData();
              this.dialog = false;
            }
            // this.users = response.data;

            // this.dialogEmail = true;
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    },

    editItem(item, event) {
      this.getSignedDocs();
      console.log("name", event.currentTarget.id);
      this.signatory = event.currentTarget.id;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // this.getSignedDocs()
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // console.log(this.editedIndex);
      this.desserts.splice(this.editedIndex, 1);
      // console.log(this.editedItem);
      let data = this.editedItem;

      await axios({
        method: "post",
        url: `${url}/deletedrawDocumentWB`,
        data: data
      })
        .then(
          response => {
            console.log("The Response", response.data);

            this.closeDelete();
            this.getInitialData();
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      this.progress = true;
      let uploadArray = [];
      if (this.editedIndex > -1) {
        console.log("editedItem", this.editedItem);
        this.documentsToSign = [];
        if (this.editedItem.uploadUnsignedDoc1 !== null) {
          let insert = this.editedItem.uploadUnsignedDoc1.split("/");
          this.documentsToSign.push(insert[insert.length - 1]);
        }
        if (this.editedItem.uploadUnsignedDoc2 !== null) {
          let insert = this.editedItem.uploadUnsignedDoc2.split("/");
          this.documentsToSign.push(insert[insert.length - 1]);
        }
        if (this.editedItem.uploadUnsignedDoc3 !== null) {
          let insert = this.editedItem.uploadUnsignedDoc3.split("/");
          this.documentsToSign.push(insert[insert.length - 1]);
        }
        if (this.editedItem.uploadUnsignedDoc4 !== null) {
          let insert = this.editedItem.uploadUnsignedDoc4.split("/");
          this.documentsToSign.push(insert[insert.length - 1]);
        }
        // console.log(this.documentsToSign);

        this.uploadSignedDocUser = this.$store.state.userName;
        this.uploadSignedDocDate = dayjs(new Date()).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        // uploadSignature: null,
        this.idToEdit = this.editedItem.id;
        this.fileNameToSign = this.editedItem.unsigned1;

        if (this.signatory === "Nick Morgan") {
          this.signDocumentNick();
        } else if (this.signatory === "Wynand Haywood") {
          this.signDocumentWynand();
          console.log(this.signatory);
        } else if (this.signatory === "Deon van Rooyen") {
          this.signDocumentDeon();
          console.log(this.signatory);
        }
      } else {
        this.progress = true;
        if (this.uploadUnsignedDoc1) {
          uploadArray.push(this.uploadUnsignedDoc1);
        }
        if (this.uploadUnsignedDoc2) {
          uploadArray.push(this.uploadUnsignedDoc2);
        }
        if (this.uploadUnsignedDoc3) {
          uploadArray.push(this.uploadUnsignedDoc3);
        }
        if (this.uploadUnsignedDoc4) {
          uploadArray.push(this.uploadUnsignedDoc4);
        }
        if (this.uploadUnsignedDoc5) {
          uploadArray.push(this.uploadUnsignedDoc5);
        }
        this.editedItem.uploadUnsignedDocUser = this.$store.state.userName;
        this.editedItem.uploadUnsignedDocDate = dayjs(new Date()).format(
          "YYYY-MM-DD"
        );
        // console.log("uploadArray", uploadArray);
        let formData = new FormData();
        uploadArray.forEach(el => {
          formData.append("files", el);
        });
        // formData.append("documents", uploadArray)
        formData.append("user", this.$store.state.userName);
        formData.append("subNumber", this.submissionNumber);
        formData.append("development", this.$store.state.development.id);
        formData.append(
          "date",
          dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss")
        );
        await axios({
          method: "post",
          url: `${url}/uploadUnsignedDrawDocumentsWB`,
          data: formData
        })
          .then(
            response => {
              console.log("The Response", response.data);
              this.uploadUnsignedDoc1 = null;
              this.uploadUnsignedDoc2 = null;
              this.uploadUnsignedDoc3 = null;
              this.uploadUnsignedDoc4 = null;
              this.uploadUnsignedDoc5 = null;
              this.progress = false;
              setTimeout(() => {
                // window.reload()
                this.getInitialData();
              }, 500);
            },
            error => {
              console.log(error);
            }
          )
          .catch(e => {
            console.log(e);
          });
      }
      this.close();
    }
  }
};
</script>

<style>
.myClass td {
  font-size: small !important;
  height: 0 !important;
  padding: 1px !important;
}
.myClass {
  margin-left: 30px;
  margin-right: 30px;
}
.a-class {
  width: 100%;
  height: 100%;
}
</style>
